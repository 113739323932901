import React, { Suspense } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import DefaultLayout from '@/components/Layout/Default';
import NProgress from '@/components/UI/NProgress';

import Index from '@/views/Index';
import Ecosystem from '@/views/Ecosystem';
import MediaKit from '@/views/MediaKit';

const About = React.lazy(() => import('@/views/About'));

const Routes = () => {
  return (
    <Suspense fallback={<NProgress />}>
      <ReactRoutes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" key="Index" element={<Index />} />
          
          <Route path="/about" key="About" element={<About />} />
          <Route path="/ecosystem" key="Ecosystem" element={<Ecosystem />} />
          <Route path="/mediaKit" key="MediaKit" element={<MediaKit />} />
        </Route>
      </ReactRoutes>
    </Suspense>
  );
};

export default Routes;
