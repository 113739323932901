export const PROJECT_LIST = [
  {
    name: 'PoseiSwap',
    url: 'https://poseiswap.xyz',
    type: 'DeFi',
    icon: 'posei',
  },
  {
    name: 'Coral Finance',
    url: 'https://coraldex.finance/',
    type: 'DeFi',
    icon: 'coral',
  },
  {
    name: 'DBOE',
    url: 'https://t.co/OZT8umxss5',
    type: 'DeFi',
    icon: 'DBOE',
  },
  { name: 'Dripverse', url: 'https://dripverse.org/', type: 'NFT', icon: 'Dripverse' },
  {
    name: 'Hypaspace',
    url: '',
    type: 'Infra',
    icon: 'hypaspace',
  },
  {
    name: 'HexLink',
    url: '',
    type: 'Infra',
    icon: 'HexLink',
  },
  {
    name: 'Hats Finance',
    url: '',
    type: 'DeFi/Privacy',
    icon: 'HatsFinance',
  },
  {
    name: 'Starship',
    url: '',
    type: 'Gaming',
    icon: 'StarShip',
  },
  {
    name: 'OriginX',
    url: '',
    type: 'DAO',
    icon: 'OriginX',
  },
  {
    name: 'Glacier',
    url: '',
    type: '',
    icon: 'GlacierNetwork',
  },
  {
    name: 'Hyperstation',
    url: '',
    type: 'DAO',
    icon: 'HyperStation',
  },
  {
    name: 'Shield',
    url: 'https://shieldprotocol.org/',
    type: 'Wallet',
    icon: 'ShieldProtocol',
  },
  {
    name: 'NFTs2Me',
    url: '',
    type: 'NFT',
    icon: 'NFTs2Me',
  },
  {
    name: 'Melos',
    url: 'https://melos.studio/',
    type: 'NFT',
    icon: 'melos',
  },
  {
    name: 'Seer',
    url: 'https://www.seer.eco/',
    type: 'Tooling',
    icon: 'seer',
  },
  {
    name: 'Securo',
    url: '',
    type: 'Tooling',
    icon: 'Securo',
  },
  {
    name: 'Tranching',
    url: '',
    type: 'Tooling',
    icon: '',
  },
  {
    name: 'Meson',
    url: 'https://www.meson.network/',
    type: 'DeFi',
    icon: 'meson',
  },
  {
    name: 'Knightswap',
    url: 'https://www.knightswap.financial/',
    type: 'DeFi',
    icon: 'KnightSwap',
  },
  {
    name: 'Esol Labs',
    url: '',
    type: 'Oracle',
    icon: 'EsolLabs',
  },
  {
    name: 'ApeNFT',
    url: '',
    type: 'NFT',
    icon: '',
  },
  {
    name: 'MailZero',
    url: '',
    type: 'Tooling',
    icon: '',
  },
  {
    name: 'AsetPay',
    url: '',
    type: 'NFT',
    icon: '',
  },
  {
    name: 'Jackie Labs',
    url: '',
    type: 'Gaming',
    icon: '',
  },
  {
    name: 'SolarDEX',
    url: '',
    type: 'DeFi',
    icon: 'SolarDex',
  },
  {
    name: 'MMO',
    url: 'https://mmocoin.pro/',
    type: 'DeFi',
    icon: 'MMOFinance',
  },
  {
    name: 'Chainport',
    url: 'http://chainport.io',
    type: 'DeFi',
    icon: '',
  },
  {
    name: 'Startupswap',
    url: 'https://startupswap.io/',
    type: 'DeFi',
    icon: 'Startupswap',
  },
  {
    name: 'OliveX',
    url: 'http://olivex.pro/',
    type: 'DeFi',
    icon: 'olivex',
  },
  {
    name: 'Coreto',
    url: 'https://www.coreto.io/',
    type: 'DeFi',
    icon: 'Coreto',
  },
  {
    name: 'Stafi Protocol',
    url: 'https://www.stafi.io/',
    type: 'DeFi',
    icon: 'StafiProtocol',
  },
  {
    name: 'Notifi',
    url: 'https://notifi.network/',
    type: 'Tooling',
    icon: 'Notifi',
  },
  {
    name: 'ThirdFi',
    url: 'https://www.thirdfi.org/',
    type: 'DeFi',
    icon: 'thirdfi',
  },
  {
    name: 'Fabwelt',
    url: 'https://www.fabwelt.com/',
    type: 'Gaming',
    icon: 'fabwelt',
  },
  {
    name: 'Benny',
    url: '',
    type: '',
    icon: '',
  },
  {
    name: 'Zoksh',
    url: 'https://zoksh.com/',
    type: 'DeFi',
    icon: 'Zoksh',
  },
  {
    name: 'Lofi',
    url: '',
    type: 'DeFi',
    icon: '',
  },
  // {
  //   name: 'PolyNetwork',
  //   url: 'https://poly.network/#/',
  //   type: 'Cross-chain',
  //   icon: 'polynetwork',
  // },
  {
    name: 'Openfabric Ai',
    url: 'https://openfabric.ai/',
    type: 'Cross-chain',
    icon: 'openfabric',
  },
  // {
  //   name: 'MultiChain',
  //   url: 'https://www.multichain.com/',
  //   type: 'Dapp',
  //   icon: 'multichain',
  // },
  { name: 'Getaverse', url: 'https://getaverses.com/', type: 'Privacy', icon: 'gentaverse' },
  {
    name: 'Openfabric',
    url: 'https://openfabric.ai/',
    type: 'Cross-chain',
    icon: 'openfabric',
  },
  {
    name: 'YFIONE',
    url: 'https://yfione.org/#/',
    type: 'DeFi',
    icon: 'yfione',
  },
  {
    name: 'Leonicorn',
    url: 'https://www.leonicornswap.com/',
    type: 'DeFi',
    icon: 'leonicorn',
  },
  {
    name: 'Defina',
    url: 'https://defina.finance/zh/',
    type: 'NFT',
    icon: 'defina',
  },
  {
    name: 'Carbon',
    url: 'https://carbon.website/',
    type: 'Privacy',
    icon: 'carbon',
  },
  {
    name: 'Currate',
    url: 'https://web.curate.style/',
    type: 'DeFi',
    icon: 'currate',
  },
  {
    name: 'Civilization',
    url: 'https://cvl.network/',
    type: 'DeFi',
    icon: 'cvl',
  },
  {
    name: 'RYI Unity',
    url: 'https://ryi-unity.com/',
    type: 'DeFi',
    icon: 'RYI',
  },
  {
    name: 'DALM',
    url: 'https://dalmatian.finance/',
    type: 'Gaming',
    icon: 'dalm',
  },
  {
    name: 'Zuraverse',
    url: 'https://zuraverse.xyz/',
    type: 'Gaming',
    icon: 'zuraverse',
  },

  {
    name: 'Zetos',
    url: 'https://zetos.finance/#/',
    type: 'DeFi',
    icon: 'zetos',
  },
  {
    name: 'DEXsport',
    url: 'https://dexsport.io/',
    type: 'DeFi',
    icon: 'dexsport',
  },
  {
    name: 'Sociapol',
    url: 'https://www.sociapol.com/',
    type: 'DeFi',
    icon: 'sociapol',
  },
  {
    name: 'Navis',
    url: 'https://navistoken.com/',
    type: 'DeFi',
    icon: 'navis',
  },
  {
    name: 'Chainport',
    url: 'https://www.chainport.io/',
    type: 'Cross-chain',
    icon: 'chainport',
  },
  {
    name: 'Questbook',
    url: 'https://openquest.xyz/',
    type: 'Tooling',
    icon: 'questbook',
  },
  {
    name: 'Ojo',
    url: 'https://ojo.network/',
    type: 'Oracle',
    icon: 'ojo',
  },
  {
    name: 'DIA',
    url: 'https://www.diadata.org/',
    type: 'Oracle',
    icon: 'DIA',
  },
  {
    name: 'Pyth',
    url: 'https://pyth.network/',
    type: 'Oracle',
    icon: 'Pyth',
  },
  {
    name: 'Nexter',
    url: 'https://app.nexter.fi/',
    type: 'Gaming',
    icon: 'nexter',
  },
  {
    name: 'Matrix',
    url: 'https://www.matrix.io/',
    type: 'NFT',
    icon: 'matrix',
  },
  {
    name: 'Smart Finance',
    url: 'https://smartgame.finance/',
    type: 'DeFi',
    icon: 'smartfinance',
  },
  {
    name: 'DeFund',
    url: 'https://defund.io/',
    type: 'Infra',
    icon: 'defund',
  },
  {
    name: 'HAPI',
    url: 'https://hapi.one/',
    type: 'Privacy',
    icon: 'hapi',
  },
  {
    name: 'Router',
    url: 'https://www.routerprotocol.com/',
    type: 'Cross-chain',
    icon: 'router',
  },
  {
    name: 'Fanta Gaming',
    url: 'https://www.fantaverse.io/',
    type: 'Gaming',
    icon: 'fv',
  },
  {
    name: 'Socialpol',
    url: 'https://www.sociapol.com/',
    type: 'Gaming',
    icon: '',
  },
  {
    name: 'AI City',
    url: '',
    type: 'Gaming',
    icon: 'AICity',
  },
  {
    name: 'MixPay',
    url: 'https://mixpay.me/',
    type: 'Tooling',
    icon: 'mixpay',
  },
  {
    name: 'RSS3',
    url: 'https://rss3.io/',
    type: 'Tooling',
    icon: 'rss3',
  },
  {
    name: 'Ruby',
    url: '',
    type: 'Wallet',
    icon: 'Ruby',
  },
  {
    name: 'XTCL',
    url: '',
    type: '',
    icon: '',
  },
  {
    name: 'Pimlico',
    url: '',
    type: '',
    icon: '',
  },
  {
    name: 'Passion Guild',
    url: '',
    type: '',
    icon: 'PassionGuild',
  },
  {
    name: 'Nimbus',
    url: '',
    type: '',
    icon: '',
  },
  {
    name: 'Footprint',
    url: 'https://www.footprint.network/',
    type: 'infra',
    icon: 'footprint',
  },
  { name: 'PLAZADAO', url: 'http://plazadao.io/', type: 'NFT', icon: 'plazadao' },
  { name: 'ZKPengs', url: 'https://zkpengz.com/', type: 'NFT', icon: 'ZKPengs' },
  { name: 'No Drama LLamas', url: '', type: 'NFT', icon: 'NoDramaLlamas' },
  { name: 'Alphaguilty', url: 'https://alphaguilty.io/', type: 'NFT', icon: 'Alphaguilty' },
  { name: 'Loozr', url: 'https://loozr.io/', type: 'NFT', icon: 'Loozr' },
  { name: 'Clockcity', url: 'https://clockcity.club/', type: 'NFT', icon: '' },
  { name: 'Pixtalgia', url: 'https://www.pixtalgia.com/', type: 'NFT', icon: 'Pixtalgia' },
  { name: 'Ampy', url: '', type: 'NFT', icon: 'Ampy' },
  { name: 'Treasure', url: '', type: 'NFT', icon: 'Treasure' },
  { name: 'Chamcha Ordinals', url: '', type: 'NFT', icon: 'ChamchaOrdinals' },
  { name: 'Rewards Bunny', url: '', type: 'NFT', icon: '' },
  {
    name: 'Duel Network',
    url: 'https://play.duel.network/',
    type: 'Gaming',
    icon: 'duelnetwork',
  },
  {
    name: 'RXC games',
    url: '',
    type: 'Gaming',
    icon: '',
  },
  {
    name: 'Gamma',
    url: '',
    type: 'Privacy',
    icon: '',
  },
  {
    name: 'Al NFT generator',
    url: '',
    type: 'NFT',
    icon: '',
  },
  {
    name: 'HYVE',
    url: '',
    type: 'Oracle',
    icon: '',
  },
  {
    name: 'Fam3',
    url: '',
    type: 'Gaming',
    icon: '',
  },
  {
    name: 'BelecX',
    url: 'https://belecx.com/',
    type: 'DeFi',
    icon: 'belecx',
  },
  {
    name: 'LTW',
    url: '',
    type: 'DeFi',
    icon: '',
  },
  {
    name: 'Avalonix',
    url: 'https://link3.to/quantrp',
    type: 'Gaming',
    icon: 'avalonix',
  },
  {
    name: 'Swagga',
    url: 'https://swagga.io/nftshop',
    type: 'Infra',
    icon: 'swagga',
  },
  {
    name: '4EVERLAND',
    url: 'https://www.4everland.org/',
    type: 'Infra',
    icon: '4everland',
  },
  {
    name: 'Witnet',
    url: '',
    type: 'Infra/Data',
    icon: '',
  },
  {
    name: 'Midle',
    url: 'https://midle.io/',
    type: 'Cross-chain',
    icon: 'midle',
  },
  {
    name: 'SWFT',
    url: 'https://www.swft.pro/',
    type: 'Cross-chain',
    icon: 'SWFT',
  },
  {
    name: 'Mixin',
    url: '',
    type: 'Cross-chain',
    icon: '',
  },
  {
    name: 'Realis',
    url: 'https://realis.network/',
    type: 'Gaming',
    icon: 'realis',
  },
  {
    name: 'Colexion',
    url: 'https://colexion.io/',
    type: 'Gaming',
    icon: 'Colexion',
  },
  {
    name: 'Quantum Leap',
    url: '',
    type: 'Gaming',
    icon: 'QuantumLeap',
  },
  {
    name: 'Diviner Protocol',
    url: 'https://www.diviner.finance/',
    type: 'Gaming',
    icon: 'DivinerProtocol',
  },
  {
    name: 'LYNC',
    url: '',
    type: 'Gaming',
    icon: 'LYNC',
  },
  {
    name: 'Gameverse',
    url: '',
    type: 'Gaming',
    icon: '',
  },
  {
    name: 'Lazyfi',
    url: 'https://www.ludero.com/',
    type: 'Gaming',
    icon: 'LazyFi',
  },
  {
    name: 'Joysticklabs',
    url: 'https://joysticklabs.io/',
    type: 'Gaming',
    icon: 'Joysticklabs',
  },
  {
    name: 'Wanderer Game',
    url: 'https://www.wanderers.ai/',
    type: 'Gaming',
    icon: 'WandererGame',
  },
  {
    name: 'Certux',
    url: 'https://certux.xyz/',
    type: 'Gaming',
    icon: '',
  },
  {
    name: 'Dolia Cats Labs',
    url: 'https://www.doliacats.xyz/',
    type: 'Gaming',
    icon: 'DoliaCatslabs',
  },
  {
    name: 'BIBI',
    url: '',
    type: 'Gaming',
    icon: 'BIBI',
  },
  {
    name: 'PickupSocial',
    url: 'https://www.pickup.social/',
    type: 'NFT',
    icon: 'PickupSocial',
  },
  {
    name: 'Gatekeeper',
    url: '',
    type: 'NFT',
    icon: '',
  },
  {
    name: 'Pitchtalk',
    url: 'https://pitchtalk.com/',
    type: 'Infra',
    icon: 'pitchtalk',
  },
  {
    name: 'Holistic Pilgrims',
    url: 'https://nearholistic.com/',
    type: 'Cross-chain',
    icon: 'holistic',
  },
  {
    name: 'ZeroLoss',
    url: 'https://zeroloss.finance/',
    type: 'DeFi',
    icon: 'zeroloss',
  },
  {
    name: 'Hyperlane',
    url: 'https://www.hyperlane.xyz/',
    type: 'Tooling',
    icon: 'hyperlane',
  },
  {
    name: 'Kimberlite',
    url: 'https://kimberlite.rocks/',
    type: 'DeFi',
    icon: 'kimberlite',
  },
  {
    name: 'Wateenswap',
    url: 'https://www.wateenswap.finance/',
    type: 'DeFi',
    icon: 'wateenswap',
  },
  {
    name: 'Settle',
    url: 'https://www.settlenetwork.com/',
    type: 'DeFi',
    icon: '',
  },
  {
    name: 'GemPad',
    url: '',
    type: 'DeFi',
    icon: '',
  },
  {
    name: 'TigervVcDao',
    url: 'https://tigervcdao.com',
    type: 'DAO',
    icon: 'tigervvcdao',
  },
  {
    name: 'Dyleum',
    url: 'https://dyleum.com/',
    type: 'DeFi',
    icon: 'Dyleum',
  },
  {
    name: 'Swapsicle',
    url: 'https://www.swapsicle.io/',
    type: 'DeFi',
    icon: 'swapsicle',
  },
  {
    name: 'FirmSeed',
    url: 'https://firmseed.io/',
    type: 'DeFi',
    icon: 'FirmSeed',
  },
  {
    name: 'Aitomcat',
    url: 'https://aitom.io/',
    type: 'DeFi',
    icon: '',
  },
  {
    name: 'Pepehub',
    url: 'https://pepehub.io/',
    type: 'DeFi',
    icon: 'Pepehub',
  },
  {
    name: 'Buff Network',
    url: '',
    type: 'DeFi',
    icon: 'BuffNetwork',
  },
  {
    name: 'Folks Finance',
    url: '',
    type: 'DeFi',
    icon: 'FolksFinance',
  },
  {
    name: 'Afam',
    url: '',
    type: 'DeFi',
    icon: '',
  },
  {
    name: 'Cedro',
    url: 'https://cedro.finance/',
    type: 'DeFi',
    icon: 'Cedro',
  },
  {
    name: 'UniverseSwap',
    url: 'https://www.universeswap.trade/',
    type: 'DeFi',
    icon: 'UniverseSwap',
  },
  {
    name: 'ShadowSwap',
    url: '',
    type: 'DeFi',
    icon: 'ShadowSwap',
  },
  {
    name: 'Nas Dex',
    url: 'https://www.nasdex.xyz/',
    type: 'DeFi',
    icon: 'NasDex',
  },
  {
    name: 'Wall App',
    url: '',
    type: 'DeFi',
    icon: 'WallApp',
  },
  {
    name: 'Sphynx Labs',
    url: '',
    type: 'DeFi',
    icon: 'SphynxLabs',
  },
  {
    name: 'Unipilot',
    url: '',
    type: 'DeFi',
    icon: 'Unipilot',
  },
  {
    name: 'GasLockr',
    url: '',
    type: 'DeFi',
    icon: 'GasLockr',
  },
  {
    name: 'Cryptoblab',
    url: 'https://cryptoblab.xyz/',
    type: 'Tooling',
    icon: 'Cryptoblab',
  },
  {
    name: 'Cyberconnect',
    url: 'https://cyberconnect.me/',
    type: 'Tooling',
    icon: 'Cyberconnect',
  },
  {
    name: 'Zealy',
    url: '',
    type: 'Tooling',
    icon: '',
  },
  {
    name: 'Mail3',
    url: 'https://mail3.me/',
    type: 'Tooling',
    icon: 'Mail3',
  },
  // { name: 'Metapoly', type: 'Tooling/Dapp', icon: 'metapoly' },
  // {
  //   name: 'Ironblock',
  //   url: 'https://www.ironblocks.com/',
  //   type: 'Privacy',
  //   icon: 'ironblocks',
  // },
  // {
  //   name: 'DeFiner',
  //   url: 'https://definer.org/',
  //   type: 'DeFi',
  //   icon: 'definer',
  // },
  // {
  //   name: 'LFW',
  //   url: 'https://lfw.fi/',
  //   type: 'Dex/DeFi',
  //   icon: 'lfw',
  // },
  {
    name: 'Merkle DAO',
    url: '',
    type: 'DAO',
    icon: 'merkle',
  },
  {
    name: 'Dmail',
    url: '',
    type: 'Infra',
    icon: 'Dmail',
  },
  {
    name: 'Wasabi',
    url: '',
    type: '',
    icon: '',
  },
  {
    name: 'Mathwallet',
    url: 'https://mathwallet.org/',
    type: 'Wallet/Infra',
    icon: 'mathwallet',
  },
  { name: 'Coin98', url: 'https://coin98.com/', type: 'Wallet/Infra', icon: 'coin98' },
  {
    name: 'Safepal',
    url: 'https://www.safepal.com/',
    type: 'Wallet/Infra',
    icon: 'safepal',
  },
  {
    name: 'Onto Wallet',
    url: 'https://onto.app/',
    type: 'Wallet/Infra',
    icon: 'onto',
  },
  {
    name: 'Bitget Wallet',
    url: 'https://web3.bitget.com/',
    type: 'Wallet/Infra',
    icon: 'Bitget',
  },
  {
    name: 'MatryX',
    url: 'https://matryx.pro/',
    type: 'NFT',
    icon: 'matryx',
  },
  {
    name: 'Rotto token',
    url: 'https://www.rottolabs.com/',
    type: 'NFT',
    icon: 'Rottotoken',
  },
  

  // { name: 'Altural', icon: 'alrura' },
  // {
  //   "name": "Nabox",
  //   "url": "https://nabox.io/",
  //   "type": "Wallet/Infra"
  // },

  // {
  //   "name": "Tokenfarm",
  //   "url": "https://tokensfarm.com/",
  //   "type": "DeFi"
  // },
  // {
  //   "name": "Crypto Godz",
  //   "url": "https://www.godzrising.io/",
  //   "type": "NFT/Gaming"
  // },
  // { "name": "Ballhunter", "url": "https://ballhunter.online/#/" },

  // {
  //   "name": "Bitkeep",
  //   "url": "https://bitkeep.com/en/index",
  //   "type": "Wallet/Infra"
  // },
  // {
  //   "name": "Moonfarm",
  //   "url": "https://moonfarm.finance/",
  //   "type": "DeFi"
  // },
  // { "name": "Spiritswap" },

  // {
  //   "name": "Termfinance",
  //   "url": "https://mytermfinance.com/en-CB/",
  //   "type": "DeFi"
  // },

  // {
  //   "name": "ChaingeFinance",
  //   "url": "https://hub.chainge.finance/",
  //   "type": "Wallet/Infra"
  // },

  // {
  //   "name": "Builder tribe",
  //   "url": "https://buidlerstribe.com/",
  //   "type": "Tooling/Dapp"
  // },
  // { "name": "b4fomo", "url": "http://b4fomo.io/" },
  // { "name": "Rambox", "url": "https://rambox.io/", "type": "DeFi" },

  // { "name": "go space", "url": "https://www.go-space.io/", "type": "Games" },

  // { "name": "ktalk", "type": "Social" },
  // { "name": "protradex", "url": "https://protradex.finance/", "type": "DeFi" },
  // { "name": "umi", "url": "http://umisfriends.com/home", "type": "NFT" },
  // { "name": "OceanPark", "type": "NFT/Gaming" },
  // { "name": "curve fork", "type": "DeFi" }
];
