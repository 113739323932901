import classNames from 'classnames';
import { isFunction } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

export function InfoItem({
  title,
  description,
  titleClassNames,
  animation,
  reverse,
  containerStyle,
  listenScrolling,
  descriptionClassNames,
  leftClassNames,
  rightClassNames,
  titleMark,
  onTypeEnd,
  queueJsx,
}: {
  title: string;
  titleMark?: any;
  description?: ((status: boolean) => any) | string;
  descriptionWidth?: number;
  descriptionClassNames?: string;
  leftClassNames?: string;
  rightClassNames?: string;
  width?: number;
  titleClassNames?: string;
  animation?: (status: boolean) => any;
  reverse?: boolean;
  containerStyle?: any;
  listenScrolling?: boolean;
  onTypeEnd?: () => void;
  queueJsx?: (status: boolean) => any;
}) {
  const [cursor, setCursor] = useState(true);

  const [ender, setEnder] = useState(!listenScrolling);

  const dom = useRef<any>();

  const bindHandleScroll = () => {
    const top = dom.current?.getBoundingClientRect?.().top;

    const height = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (top && top >= 0 && top <= height) {
      setEnder(true);
    }
  };

  useEffect(() => {
    if (!listenScrolling) return;
    window.addEventListener('scroll', bindHandleScroll);
    return () => {
      window.removeEventListener('scroll', bindHandleScroll);
    };
  }, [listenScrolling]);

  return (
    <>
      <div
        ref={dom}
        className={classNames(
          `
          w-full flex items-center justify-between z-20 bg-no-repeat
          md:flex-wrap md:justify-center gap-x-2 overflow-hidden
          py-[10px]
        `,
          {
            'flex-row-reverse': reverse,
          },
        )}
        style={containerStyle}
      >
        <div className={classNames('text-left md:w-full', animation ? 'w-3/5' : 'w-full', leftClassNames)}>
          {titleMark}
          {ender && (
            <div>
              <TypeAnimation
                className={classNames(
                  'type text-left text-[40px] 2xl:text-[40px] xl:text-[32px] lg:text-[26px] md:text-[24px]',
                  titleClassNames,
                  `
                   md:w-full
                  `,
                  reverse ? 'text-right md:text-left' : '',
                )}
                speed={75}
                sequence={[
                  title,
                  100,
                  () => {
                    setCursor(false);
                    onTypeEnd?.();
                  },
                ]}
                wrapper="p"
                cursor={cursor}
              />
            </div>
          )}

          <div
            className={classNames(
              `
              text-secondTextColor text-sm mt-[40px] leading-8
                md:w-full
              ${descriptionClassNames}
              `,
              reverse ? 'text-right md:text-left' : '',
            )}
          >
            {isFunction(description) ? description?.(!cursor) : description}
          </div>
        </div>
        {animation && (
          <div
            className={classNames(
              'md:mt-[80px] w-2/5 md:w-[70%] md:mr-auto flex items-center',
              reverse ? 'justify-start' : 'justify-end',
              'md:justify-start',
              rightClassNames
            )}
          >
            {animation?.(!cursor)}
          </div>
        )}
      </div>

      {!cursor && queueJsx?.(!cursor)}
    </>
  );
}

// <div className={classNames('md:mt-[80px] w-2/5 md:w-full flex items-center')}>
// <div
//   className={classNames('w-full md:w-[60%]', reverse ? 'justify-start' : 'justify-end', 'md:justify-start')}
// >
//   {animation?.(!cursor)}
// </div>
// </div>
