import { HashRouter } from 'react-router-dom';
import { DAppProvider } from '@usedapp/core';
import { Theme } from '@/components/app/Theme';
import Routes from './routes';
import config from '@/common/config';

import '@/assets/css/tailwind.css';
import '@/assets/css/global.css';

function App() {
  return (
    <HashRouter>
      <DAppProvider config={config.chain}>
        <Theme>
          <Routes />
        </Theme>
      </DAppProvider>
    </HashRouter>
  );
}

export default App;
