// import classNames from 'classnames';
// import { NavLink } from 'react-router-dom';
import Media from '../Media/Media';

// const menus = [
//   {
//     label: 'About Nautilus',
//     path: '/',
//   },
//   {
//     label: 'Doc',
//     path: '',
//   },
//   {
//     label: 'Report',
//     path: '',
//   },
// ];

function Footer() {
  return (
    <div className="flex justify-between items-center px-6 relative py-8  border-t border-[#333333] border-solid flex-wrap md:flex-col">
      {/* <div className={'flex justify-between items-center flex-wrap md:text-right'}>
        {menus.map((item) => (
          <div
            className={classNames(
              `
                px-5 py-3 transition-all cursor-pointer
                rounded-md color-[#CDCDCD]
                md:w-[100vw]
              `,
            )}
            key={item.label}
          >
            <NavLink to={item.path}>{item.label}</NavLink>
          </div>
        ))}
      </div> */}
      <Media />
      <div className="text-secondTextColor md:text-[10px] md:mt-[15px]">All rights reserved 2022-2023</div>
    </div>
  );
}

export default Footer;
