import { useWindowDimensions } from '@/common/hooks/useWindowDimensions';
import Footer from '@/components/UI/Footer';
import Header from '@/components/UI/Header';
import { InfoItem } from '@/components/UI/InfoItem';
import { Dropdown, List } from 'antd';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { flattenDeep, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import './style.css';
import { PROJECT_LIST } from './data';

const getTypeList = () => {
  const list = PROJECT_LIST.filter((item) => item.type && item.icon).map((item) => {
    return item.type?.split('/');
  });

  return uniq(['All', ...flattenDeep(list)]);
};

function Ecosystem() {
  const [currentType, setCurrentType] = useState('All');

  const typeList = getTypeList();

  const logoList = useMemo(() => {
    if (currentType === 'All') return PROJECT_LIST.filter((item) => item.icon);
    return PROJECT_LIST.filter((item) => item.type?.includes(currentType) && item.icon);
  }, [currentType]);

  const { width: screenWidth } = useWindowDimensions();

  const isMobile = useMemo(() => {
    return screenWidth <= 768;
  }, [screenWidth]);

  return (
    <div id="main" className="bg-[#161616] ecosystem">
      <div
        className="w-[100vw] h-[100vh] bg-cover bg-no-repeat relative z-20"
        style={{
          backgroundImage: `url(${'/static/images/Ecosystem/background.png'})`,
          backgroundSize: '100% 100%',
        }}
      >
        <div className="absolute left-0 top-0">
          <Header></Header>
        </div>
        <div
          className="
          n-warp h-full flex justify-center items-start flex-col 
          md:justify-end md:pb-[120px]
        "
        >
          <div className="py-[100px] md:py-[30px]">
            <InfoItem
              listenScrolling={false}
              descriptionClassNames="text-center"
              titleClassNames="text-[40px] text-center"
              title={'The first streaming payment blockchain'}
              description={(status) => {
                return (
                  <motion.div
                    initial={{
                      opacity: 0,
                      translateY: '100px',
                    }}
                    animate={{
                      opacity: status ? 1 : 0,
                      translateY: status ? '0px' : '100px',
                    }}
                    transition={{
                      duration: 0.5,
                    }}
                  >
                    Explore some of the hundreds of projects from DeFi to NFTs building on Nautilus.
                  </motion.div>
                );
              }}
            />
          </div>
        </div>
      </div>

      <div className="w-full min-h-[100vh] md:min-h-[auto] md:pb-[100px] flex justify-between items-start md:justify-start px-[100px] py-[80px] flex-wrap md:px-[20px] md:py-[30px]">
        <div
          className="md:hidden flex flex-col justify-start items-start md:w-full md:flex-row overflow-x-auto md:gap-x-6 mb-[10px]"
          style={{
            width: isMobile ? '100%' : '200px',
          }}
        >
          {typeList.map((item) => {
            return (
              <div
                className={classNames(
                  'flex justify-start items-center mb-3 cursor-pointer w-auto',
                  item === currentType ? 'opacity-100' : 'opacity-50',
                )}
                key={item}
                onClick={() => setCurrentType(item!)}
              >
                <img
                  src="/static/images/Ecosystem/mark.svg"
                  className={classNames('opacity-0 mr-3', {
                    'opacity-100': item === currentType,
                  })}
                  alt=""
                />
                {item}
              </div>
            );
          })}
        </div>

        <div className="hidden md:block mb-6">
          <Dropdown
            trigger={['click']}
            menu={{
              items: typeList.map((item) => {
                return {
                  key: item,
                  label: <div onClick={() => setCurrentType(item!)}>{item}</div>,
                };
              }) as any,
            }}
          >
            <div className="flex justify-start items-center mb-3 cursor-pointer w-auto">
              <img
                src="/static/images/Ecosystem/mark.svg"
                className={classNames('opacity-100 mr-3 mt-[-3px]', {})}
                alt=""
              />
              {currentType}
              <img
                src="/static/images/arrow.svg"
                className={classNames('opacity-100 w-[18px] ml-3 mt-[-3px]', {})}
                alt=""
              />
            </div>
          </Dropdown>
        </div>

        {/* flex justify-start items-start flex-wrap  */}

        <div
          key={logoList.length}
          className="
            gap-x-3 gap-y-3 w-5/6 md:w-full
            "
          style={{
            width: isMobile ? '100%' : 'calc(100% - 200px)',
          }}
        >
          <List
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 4,
              xxl: 6,
            }}
            dataSource={logoList}
            renderItem={(item) => (
              <List.Item>
                <div className="ecosystem-item flex flex-col justify-center items-center aspect-auto">
                  <div
                    className={classNames('w-[100%] pb-[100%] mb-3 flex justify-center items-center relative', {
                      'cursor-pointer': item.url,
                    })}
                    style={{
                      backgroundImage: `url(${'/static/images/Ecosystem/itemBg.svg'})`,
                      backgroundSize: '100% 100%',
                    }}
                    onClick={() => {
                      if (!item.url) return;
                      window.open(item.url);
                    }}
                  >
                    <div
                      className={classNames(
                        `ecosystem-item-o w-full h-full bg-no-repeat absolute left-0 top-0 z-10 opacity-0`,
                      )}
                      style={{
                        backgroundImage: `url(${
                          item.icon
                            ? `/static/images/Ecosystem/logo/${item.icon}_o.svg`
                            : '/static/images/placeholer.svg'
                        }), url(/static/images/Ecosystem/logo/${item.icon}_o.png)`,
                        backgroundSize: '90%',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                    <div
                      className={classNames(`w-full h-full bg-no-repeat absolute left-0 top-0 z-0`)}
                      style={{
                        backgroundImage: `url(${
                          item.icon
                            ? `/static/images/Ecosystem/logo/${item.icon}_w.svg`
                            : '/static/images/placeholer.svg'
                        }), url(/static/images/Ecosystem/logo/${item.icon}_w.png)`,
                        backgroundSize: '90%',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                  </div>
                  <div className="text-secondTextColor md:text-[14px] ecosystem-item-text font-[PressStart2P]">
                    {item.name}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Ecosystem;
