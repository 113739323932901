import React from 'react';

const MEDIA = [
  { name: 'Twitter', link: 'https://twitter.com/nautilus_chain' },
  { name: 'Telegram', link: 'https://t.me/nautchain' },
  { name: 'Medium', link: 'https://medium.com/@nautchain' },
  // { name: 'Discord', link: 'https://discord.gg/pgkrEqRdqV' },
];

function Media() {
  return (
    <div className="flex items-center gap-x-6">
      {MEDIA.map((item) => (
        <a key={item.name} href={item.link} target="_blank" rel="noopener noreferrer">
          <img className="w-6" src={`/static/images/Media/${item.name.toLowerCase()}.svg`} alt={item.name} />
        </a>
      ))}
    </div>
  );
}

export default Media;
