import { useWindowDimensions } from '@/common/hooks/useWindowDimensions';
import Footer from '@/components/UI/Footer';
import Header from '@/components/UI/Header';
import { Dropdown, List } from 'antd';
import classNames from 'classnames';
import { flattenDeep, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import data from './data.json';
import './style.css';

const getTypeList = () => {
  const list = data
    .filter((item) => item.type)
    .map((item) => {
      return item.type?.split('/');
    });

  return uniq(['All', ...flattenDeep(list)]);
};

function MediaKit() {
  const [currentType, setCurrentType] = useState('All');

  const typeList = getTypeList();

  const logoList = useMemo(() => {
    if (currentType === 'All') return data;
    return data.filter((item) => item.type?.includes(currentType));
  }, [currentType]);

  const { width: screenWidth } = useWindowDimensions();

  const isMobile = useMemo(() => {
    return screenWidth <= 768;
  }, [screenWidth]);

  return (
    <div id="main" className="bg-[#161616] ecosystem">
      <div className="w-[100vw] h-[120px] bg-cover bg-no-repeat relative z-20">
        <div className="absolute left-0 top-0">
          <Header></Header>
        </div>
      </div>

      <div className="w-full min-h-[100vh] md:min-h-[auto] md:pb-[100px] flex justify-between items-start md:justify-start px-[100px] py-[80px] flex-wrap md:px-[20px] md:py-[30px]">
        <div
          className="md:hidden flex flex-col justify-start items-start md:w-full md:flex-row overflow-x-auto md:gap-x-6 mb-[10px]"
          style={{
            width: isMobile ? '100%' : '200px',
          }}
        >
          {typeList.map((item) => {
            return (
              <div
                className={classNames(
                  'flex justify-start items-center mb-3 cursor-pointer w-auto',
                  item === currentType ? 'opacity-100' : 'opacity-50',
                )}
                key={item}
                onClick={() => setCurrentType(item!)}
              >
                <img
                  src="/static/images/Ecosystem/mark.svg"
                  className={classNames('opacity-0 mr-3', {
                    'opacity-100': item === currentType,
                  })}
                  alt=""
                />
                {item}
              </div>
            );
          })}
        </div>

        <div className="hidden md:block mb-6">
          <Dropdown
            trigger={['click']}
            menu={{
              items: typeList.map((item) => {
                return {
                  key: item,
                  label: <div onClick={() => setCurrentType(item!)}>{item}</div>,
                };
              }) as any,
            }}
          >
            <div className="flex justify-start items-center mb-3 cursor-pointer w-auto">
              <img
                src="/static/images/Ecosystem/mark.svg"
                className={classNames('opacity-100 mr-3 mt-[-3px]', {})}
                alt=""
              />
              {currentType}
              <img
                src="/static/images/arrow.svg"
                className={classNames('opacity-100 w-[18px] ml-3 mt-[-3px]', {})}
                alt=""
              />
            </div>
          </Dropdown>
        </div>

        {/* flex justify-start items-start flex-wrap  */}

        <div
          key={logoList.length}
          className="
            gap-x-3 gap-y-3 w-5/6 md:w-full
            "
          style={{
            width: isMobile ? '100%' : 'calc(100% - 200px)',
          }}
        >
          <List
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={logoList}
            renderItem={(item) => (
              <List.Item>
                <a href={`/media/nuatchain_media_kit/${item.name}`} download={item.name}>
                  <div className="ecosystem-item flex flex-col justify-center items-center aspect-auto">
                    <div
                      className={classNames(
                        'w-[100%] pb-[100%] mb-3 flex justify-center items-center relative cursor-pointe',
                      )}
                      style={{
                        backgroundImage: `url(${'/static/images/Ecosystem/itemBg.svg'})`,
                        backgroundSize: '100% 100%',
                      }}
                    >
                      <div
                        className={classNames(
                          `ecosystem-item-o w-full h-full bg-no-repeat absolute left-0 top-0 z-10 `,
                        )}
                        style={{
                          backgroundImage: `url(/media/nuatchain_media_kit/${item.name})`,
                          backgroundSize: '80%',
                          backgroundPosition: 'center',
                        }}
                      ></div>
                    </div>
                    <div className="text-secondTextColor md:text-[12px] ecosystem-item-text font-[PressStart2P] lg:text-[12px]">
                      {item.name.replace('naut_', '')}
                    </div>
                  </div>
                </a>
              </List.Item>
            )}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MediaKit;
