import { Drawer, Dropdown } from 'antd';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Media from '../Media/Media';

const menus = [
  {
    label: 'Home',
    path: '/',
    href: undefined,
  },
  {
    label: 'Ecosystem',
    path: '/ecosystem',
  },
  // {
  //   label: 'Docs',
  //   href: 'https://docs.nautchain.xyz/',
  //   path: '',
  // },
  {
    label: 'Tools',
    path: '',
    menu: {
      items: [
        {
          key: '0',
          label: (
            <a
              className="hover:!text-lightTextColor"
              href="https://nautscan.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Explorer(Mainnet)
            </a>
          ),
        },
        {
          key: '1',
          label: (
            <a
              className="hover:!text-lightTextColor"
              href="https://nautilusbridge.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Official Bridge(Mainnet)
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a
              className="hover:!text-lightTextColor"
              href="https://proteusfaucet.nautchain.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Proteus Faucet(Testnet)
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a
              className="hover:!text-lightTextColor"
              href="https://proteus.nautscan.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Proteus Explorer(Testnet)
            </a>
          ),
        },
        // {
        //   key: '2',
        //   label: (
        //     <a
        //       className="hover:!text-lightTextColor"
        //       href="https://test.multichain.org/#/router"
        //       target="_blank"
        //       rel="noopener noreferrer"
        //     >
        //       Bridge (Beta-Goerli USDC only)
        //     </a>
        //   ),
        // },
        {
          key: '4',
          label: (
            <a
              className="hover:!text-lightTextColor"
              href="https://docs.nautchain.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dev Docs
            </a>
          ),
        },
      ],
    },
  },
  {
    label: 'Media Kit',
    path: '/mediaKit',
  },
  {
    label: 'Naut DID',
    href: 'https://www.nautdid.xyz',
    path: '',
  },
  // {
  //   label: 'Referral Rush(Triton)',
  //   href: 'https://refrush.nautchain.xyz',
  //   path: '',
  // },
  // {
  //   label: 'Developer',
  //   path: '',
  // },
  // {
  //   label: 'Fund',
  //   path: '',
  // },
  // {
  //   label: 'Explorer',
  //   path: '',
  // },
];

function Header() {
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <motion.div
      className="min-w-[100vw]"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
    >
      <div
        className="
          flex justify-between items-center px-5 relative py-3
          md:justify-between
        "
      >
        {/* absolute left-[55px] */}
        <div
          className="
         w-auto flex justify-center items-center 
         md:relative  md:left-[0]
         "
        >
          <img src="/static/images/logo.svg" alt="" />
        </div>

        <div className={'flex justify-between items-center md:hidden select-none'}>
          {menus.map((item) => {
            if (item.menu) {
              return (
                <Dropdown
                  menu={item.menu}
                  key={item.label}
                  overlayClassName="
                  [&_.ant-dropdown-menu]:bg-transparent
                  [&_.ant-dropdown-menu-item]:!text-[#CDCDCD]
                  [&_.ant-dropdown-menu-item]:font-[PressStart2P]
                  [&_.ant-dropdown-menu-item]:!py-2
                  "
                >
                  <h1 className="text-sm px-5 py-3 transition-all cursor-pointer border border-[#333333] border-solid rounded-md text-[#CDCDCD] hover:border-[#fff] hover:text-white">
                    {item.label}
                  </h1>
                </Dropdown>
              );
            }
            if (item.href) {
              return (
                <a
                  href={item.href}
                  key={item.label}
                  target={'_blank'}
                  rel={'noreferrer'}
                  className="text-sm px-5 py-3 transition-all cursor-pointer border border-[#333333] border-solid rounded-md text-[#CDCDCD] hover:border-[#fff] hover:text-white"
                >
                  {item.label}
                </a>
              );
            }
            return (
              <NavLink to={item.path} key={item.label}>
                <div
                  className={classNames(
                    `
              text-sm px-5 py-3 transition-all cursor-pointer
              border border-[#333333] border-solid
              rounded-md 
            `,
                    location.pathname === item.path ? 'border-[#fff] text-white' : 'text-[#CDCDCD]',
                  )}
                >
                  {item.label}
                </div>
              </NavLink>
            );
          })}
        </div>
        <Media />
        <div
          className="hidden md:block"
          onClick={() => {
            setOpen(true);
          }}
        >
          <img src="/static/images/hamburger.svg" alt="" />
        </div>
      </div>

      <Drawer
        className="drawer__right"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={'100%'}
        closable={false}
      >
        <div
          className="absolute right-[15px] top-[15px]"
          onClick={() => {
            setOpen(false);
          }}
        >
          <img src="/static/images/close.svg" alt="" />
        </div>
        <div className="mobileHeader__nav w-full px-[20px] relative">
          {menus.map((item) => {
            if (item.menu) {
              return (
                <Dropdown
                  menu={item.menu}
                  key={item.label}
                  overlayClassName="
                  [&_.ant-dropdown-menu]:bg-transparent
                  [&_.ant-dropdown-menu-item]:!text-[#CDCDCD] 
                  [&_.ant-dropdown-menu-item]:font-[PressStart2P]
                  [&_.ant-dropdown-menu-item]:!py-2
                  md:[&_.ant-dropdown-menu]:bg-primaryBackground
                  "
                >
                  <h1
                    className="w-full px-5 py-3 transition-all cursor-pointer mt-[20px]
                  border border-[#333333] border-solid
                  rounded-md color-[#CDCDCD]
                  font-[PressStart2P] hover:border-[#fff] hover:text-white"
                  >
                    {item.label}
                  </h1>
                </Dropdown>
              );
            } else if (item.href) {
              return (
                <a key={item.label} href={item.href} target={'_blank'} rel={'noreferrer'}>
                  <div
                    className={classNames(
                      `
                  w-full px-5 py-3 transition-all cursor-pointer mt-[20px]
                  border border-[#333333] border-solid
                  rounded-md color-[#CDCDCD]
                  font-[PressStart2P]
                  `,
                    )}
                  >
                    {item.label}
                  </div>
                </a>
              );
            }

            return (
              <div
                onClick={() => {
                  setOpen(false);
                  navigate(item.path);
                }}
                key={item.path}
                className={classNames(
                  `
                  w-full px-5 py-3 transition-all cursor-pointer mt-[20px]
                  border border-[#333333] border-solid
                  rounded-md color-[#CDCDCD]
                  font-[PressStart2P]
                  `,
                  {
                    'border-[#fff] text-white': location.pathname === item.path,
                  },
                )}
              >
                {item.label}
              </div>
            );
          })}
        </div>

        <div
          className="absolute w-full bottom-[25px] flex justify-center"
          onClick={() => {
            setOpen(false);
          }}
        >
          <img src="/static/images/twitter.svg" className="mr-[30px]" alt="" />
          <img src="/static/images/tg.svg" alt="" />
        </div>
      </Drawer>
    </motion.div>
  );
}

export default Header;
