/* eslint-disable react/display-name */
import Footer from '@/components/UI/Footer';
import Header from '@/components/UI/Header';
import { range } from 'lodash';
import { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Autoplay } from 'swiper';
import SwiperCore from 'swiper';

import { useWindowDimensions } from '@/common/hooks/useWindowDimensions';
import { InfoItem } from '@/components/UI/InfoItem';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import './style.css';
import { motion } from 'framer-motion';
import { useRequest } from 'ahooks';
import { getMetrics } from '@/service';
import CountUp from 'react-countup';

SwiperCore.use([Autoplay]);

function Partner() {
  const list = range(1, 16);

  const { width: screenWidth } = useWindowDimensions();

  const isMobile = useMemo(() => {
    return screenWidth <= 768;
  }, [screenWidth]);

  return (
    <Swiper
      className="[&>.swiper-wrapper]:ease-linear"
      slidesPerView={isMobile ? 3 : 6}
      autoplay={{
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        delay: 0,
      }}
      spaceBetween={30}
      freeMode={true}
      speed={3000}
      loop={true}
      pagination={{
        clickable: true,
      }}
      modules={[FreeMode, Scrollbar]}
    >
      {list.map((i) => {
        return (
          <SwiperSlide key={i}>
            <div className="h-[120px] flex justify-center items-center">
              <img
                src={`/static/images/Index/partner/image ${i}.png`}
                className="w-full h-[80px] object-contain"
                alt=""
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

function Metrics() {
  const { data } = useRequest(async () => {
    const res = await getMetrics();

    console.log('res', res);

    return [
      {
        label: 'Total Transactions',
        value: res.TRANSACTION,
      },
      {
        label: 'Total Addresses',
        value: res.UNIQUE_ADDRESS,
      },
      {
        label: 'Total Contracts',
        value: res.CONTRACT,
      },
    ];
  });

  return (
    <div className="w-full flex justify-between items-center relative z-30 mt-[-80px] flex-wrap">
      {data?.map((item) => {
        return (
          <div
            className="text-center flex flex-col w-[30%] md:w-full sm-w-full min-h-[200px] bg-cover bg-no-repeat justify-center items-center mb-[10px]"
            key={item.label}
            style={{
              backgroundImage: `url('/static/images/Index/metrics-bg.svg')`,
              backgroundSize: '100% 100%',
            }}
          >
            <div className="text-sm opacity-50 mb-[20px]">{item.label}</div>
            <CountUp className="text-[#FFFFFF] text-2xl" end={item.value} duration={2} />
          </div>
        );
      })}
    </div>
  );
}

function Index() {
  const menus = [
    {
      label: 'Build on Nautilus',
      link: 'https://docs.nautchain.xyz/',
    },
    {
      label: 'Whitepaper',
      link: '/static/files/[InternalDraft]NautilusBlockchainWhitePaperV0.2-2023May18.pdf',
    },
  ];

  const { width: screenWidth } = useWindowDimensions();

  const isMobile = useMemo(() => {
    return screenWidth <= 768;
  }, [screenWidth]);

  return (
    <div id="main" className="bg-[#161616]">
      <div
        className="w-[100vw] h-[100vh] bg-cover bg-no-repeat relative z-20"
        style={{
          backgroundImage: `url(${
            isMobile ? '/static/images/Index/mobile_boat2.gif' : '/static/images/Index/web_boat2.gif'
          })`,
          backgroundSize: '100% 100%',
        }}
      >
        <div className="absolute left-0 top-0">
          <Header></Header>
        </div>
        <div
          className="
            n-warp h-full flex justify-center items-start flex-col 
            md:justify-end md:pb-[120px]
            "
        >
          <InfoItem
            title={'Nautilus Chain'}
            description={(status) => {
              return (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateY: '100px',
                  }}
                  animate={{
                    opacity: status ? 1 : 0,
                    translateY: status ? '0px' : '100px',
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  The First Streaming Payment Blockchain
                </motion.div>
              );
            }}
            queueJsx={(status) => (
              <motion.div
                initial={{
                  opacity: 0,
                  translateY: '100px',
                }}
                animate={{
                  opacity: status ? 1 : 0,
                  translateY: status ? '0px' : '100px',
                }}
                transition={{
                  delay: 0.3,
                  duration: 0.3,
                }}
                className="mt-8"
              >
                {menus.map((item) => (
                  <a
                    className="group flex items-center justify-start mt-3
                      transition-all
                      item cursor-pointer hover:text-lightTextColor
                      translate-x-[-60px]
                      hover:translate-x-[0px]
                      select-none
                      md:translate-x-[0px]
                      "
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={item.label}
                  >
                    <img
                      className="mr-6 ship opacity-0 transition-all md:hidden"
                      src="/static/images/Index/ship.svg"
                      alt=""
                    />
                    {item.label}
                    {/* <span className="hidden group-hover:block">&nbsp;-&nbsp;Coming Soon</span> */}
                  </a>
                ))}
              </motion.div>
            )}
          />
        </div>
      </div>

      <div className="n-warp md:w-full sm:w-full">
        <Metrics></Metrics>
      </div>

      <div className="bg-cover bg-no-repeat relative">
        <div className="h-[100vh] pt-1 flex items-center">
          <div className="n-warp py-[180px]">
            <InfoItem
              leftClassNames="w-2/5 md:w-full"
              rightClassNames="w-3/5 md:w-full"
              listenScrolling={true}
              title={'Fastest Transaction Speeds Ever'}
              description={(status) => {
                return (
                  <motion.div
                    initial={{
                      opacity: 0,
                      translateX: '-00px',
                    }}
                    animate={{
                      opacity: status ? 1 : 0,
                      translateX: status ? '0px' : '-100px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                  >
                    With the help of Eclipse’s technology, Nautilus supports Ethereum, Move VM and Cosmos.
                  </motion.div>
                );
              }}
              animation={(status) => (
                <motion.div
                  className="mix-blend-lighten"
                  initial={{
                    opacity: 0,
                    translateX: '100px',
                  }}
                  animate={{
                    opacity: status ? 1 : 0,
                    translateX: status ? '0px' : '100px',
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  <img
                    className="md:w-full mix-blend-lighten"
                    // style={{
                    //   mixBlendMode: 'color',
                    // }}
                    src="/static/images/Index/fastest.png"
                    alt=""
                  />
                </motion.div>
              )}
            />
          </div>
        </div>

        <div className="n-warp py-[180px] md:py-[60px]">
          <InfoItem
            listenScrolling={true}
            reverse={true}
            title={'Most Compliant-Friendly Blockchain in Existence'}
            description={(status) => {
              return (
                <motion.div
                  initial={{
                    opacity: 0,
                    translateX: '100px',
                  }}
                  animate={{
                    opacity: status ? 1 : 0,
                    translateX: status ? '0px' : '100px',
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  Nautilus has meta transaction functions to further reduce the blockchains costs for developers and
                  users.
                </motion.div>
              );
            }}
            animation={(status) => (
              <motion.div
                className="mix-blend-screen"
                initial={{
                  opacity: 0,
                  translateX: '-100px',
                }}
                animate={{
                  opacity: status ? 1 : 0,
                  translateX: status ? '0px' : '-100px',
                }}
                transition={{
                  duration: 0.3,
                }}
              >
                <img className="mix-blend-screen md:w-full" src="/static/images/Index/02.gif" alt="" />
              </motion.div>
            )}
          />
        </div>

        <div
          style={{
            backgroundImage: 'url(/static/images/Index/bg_2.png)',
            backgroundSize: '100% 100%',
          }}
        >
          <div className="n-warp py-[180px] md:py-[60px]">
            <InfoItem
              listenScrolling={true}
              title={'Smoother Cross-Chain Experience'}
              description={(status) => {
                return (
                  <motion.div
                    initial={{
                      opacity: 0,
                      translateX: '-100px',
                    }}
                    animate={{
                      opacity: status ? 1 : 0,
                      translateX: status ? '0px' : '-100px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                  >
                    Support cross-chain asset transmission of multiple mainstream currencies to create a seamless
                    cross-chain experience.
                  </motion.div>
                );
              }}
              animation={(status) => (
                <motion.div
                  className="mix-blend-screen"
                  initial={{
                    opacity: 0,
                    translateX: '100px',
                  }}
                  animate={{
                    opacity: status ? 1 : 0,
                    translateX: status ? '0px' : '100px',
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  <img className="mix-blend-screen md:w-full" src="/static/images/Index/03.gif" alt="" />
                </motion.div>
              )}
            />
          </div>
        </div>

        <div className="bg-gradient">
          <div className="n-warp py-[100px] md:py-[30px] md:mt-[160px]">
            <InfoItem
              listenScrolling={true}
              titleClassNames="text-center md:text-left"
              title={'Join the Nautilus Chain'}
              description={(status) => {
                return (
                  <motion.div
                    initial={{
                      opacity: 0,
                      translateY: '100px',
                    }}
                    animate={{
                      opacity: status ? 1 : 0,
                      translateY: status ? '0px' : '100px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                  >
                    The best projects in DeFi, Web3 and Gamefi choose to build on Nautilus for the long term development
                    of their projects.
                  </motion.div>
                );
              }}
            />
          </div>

          <div className="w-full py-[100px]">
            <Partner />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Index;
