import axios from 'axios';

export const getMetrics = async () => {
  try {
//     const triton = axios.get('https://triton.nautscan.com/api/metrics');
//     const proteus = axios.get('https://proteus.nautscan.com/api/metrics');
    const mainnet = axios.get('https://nautscan.com/api/metrics');
    const [mainnetRes] = await Promise.all([mainnet]);
    console.log(mainnetRes)
    return {
      TRANSACTION: mainnetRes.data.result.metrics.TRANSACTION,
      UNIQUE_ADDRESS: mainnetRes.data.result.metrics.UNIQUE_ADDRESS,
      CONTRACT: mainnetRes.data.result.metrics.CONTRACT,
    };
  } catch (error) {
    return {
      TRANSACTION: 0,
      UNIQUE_ADDRESS: 0,
      CONTRACT: 0,
    };
  }
};
